import { FormControl, FormErrorMessage, FormLabel, ModalBody, ModalCloseButton, ModalHeader, useDisclosure, VStack, Select, ModalFooter, Button, useToast, HStack } from "@chakra-ui/react";
import { Children, cloneElement, useMemo, useRef, useState } from "react";
import Primary from "../../../../../Components/Buttons/Primary";
import { PrimaryInput } from "../../../../../Components/Inputs";
import PrimaryNumberInput from "../../../../../Components/Inputs/PrimaryNumberInput";
import SearchableSelector from "../../../../../Components/Inputs/SearchableSelector";
import BackdropLoader from "../../../../../Components/Loaders/BackdropLoader"
import ConfirmDialog from "../../../../../Components/micro/ConfirmDialog";
import MyModal from "../../../../../Components/micro/MyModal";
import { departmentsAtom } from "../../../../../Components/Store/atoms";
import Get from "../../../../../Components/Store/hooks/Get";

interface ErrorsType {
    name: string | null,
    quantityType: string | null,
    quantity: string | null,
}

const AddMaterialModal = ({
    refetch,
    children
}: {
    refetch: () => void,
    children: JSX.Element
}) => {
    const toast = useToast();
    const formRef = useRef<HTMLFormElement>(null);
    const [loading, setLoading] = useState<string | null>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [errors, setErrors] = useState<ErrorsType>({
        name: null,
        quantityType: null,
        quantity: null,
    });
    const departments = Get(departmentsAtom);
    const myDeparts = useMemo(() => {
        if (departments === null) return [];
        return Object.keys(departments);
    }, [departments]);

    const childWithOnClick = () => {
        return Children.map(children, child => {
            return cloneElement(child, { onClick: onOpen });
        });
    };


    const handleSubmit = () => {
        import("./handler").then(async resp => {
            if (formRef.current === null) return;
            const formData = new FormData(formRef.current);
            const success = await resp.default(formData, setErrors, setLoading, toast);
            if (success) {
                refetch();
                onClose();
                formRef.current.reset();
            }
        });
    }

    return <>
        {loading && <BackdropLoader text={loading} />}
        {childWithOnClick()}
        <MyModal
            size={"xl"}
            isOpen={isOpen}
            onClose={onClose}>
            <form onSubmit={e => {
                e.preventDefault();
            }} ref={formRef}>
                <ModalHeader>Add Raw-Material</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <VStack gap={4}>
                        <HStack w="100%" gap={4}>
                            <FormControl isRequired isInvalid={errors.name !== null}>
                                <FormLabel>Name</FormLabel>
                                <PrimaryInput name="name" placeholder='Material Name' />
                                <FormErrorMessage>{errors.name}</FormErrorMessage>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Department</FormLabel>
                                <SearchableSelector name="department" options={myDeparts.map(depart => ({
                                    value: depart,
                                    label: depart.toUpperCase()
                                }))} />
                            </FormControl>
                        </HStack>
                        <FormControl isRequired isInvalid={errors.quantityType !== null}>
                            <FormLabel>Quantity Type</FormLabel>
                            <Select name="quantity-type" focusBorderColor="primary">
                                <option value="bag">Bags(25KGs)</option>
                                <option value="kg">KGs</option>
                            </Select>
                            <FormErrorMessage>{errors.quantityType}</FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired isInvalid={errors.quantity !== null}>
                            <FormLabel>Quantity</FormLabel>
                            <PrimaryNumberInput name="quantity" placeholder='Quantity' />
                            <FormErrorMessage>{errors.quantity}</FormErrorMessage>
                        </FormControl>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <ConfirmDialog onConfirm={handleSubmit}>
                        <Primary mr={3}>Add</Primary>
                    </ConfirmDialog>
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </form>
        </MyModal>
    </>
}

export default AddMaterialModal