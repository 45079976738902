import { CardHeader, GridItem, SimpleGrid, Text, useColorMode } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { MachineHours as MH } from "../..";
import MyCard from "../../../../../../Components/micro/Card";
import ShowSelector from "../../../../../../Components/micro/ShowSelector";
import HourlyGraph from "./HourlyGraph";
import SpiderChart from "./SpiderChart";

type viewType = "shots" | "production" | "material" | "electricity";
const MachineHours = ({
    hours,
    isSingleDay
}: {
    hours: MH[],
    isSingleDay: boolean
}) => {
    const { colorMode } = useColorMode();
    const { border } = useMemo(() => ({
        border: `${colorMode}.border`
    }), [colorMode]);
    const [view, setView] = useState<viewType>("shots");

    return <MyCard p={0}>
        <CardHeader borderBottom={"1px solid"} borderColor={border} display="flex" alignItems="center" justifyContent={"space-between"} px={2} pb={1} pt={3}>
            <Text fontWeight={500} fontSize="lg">{isSingleDay ? "Machine Hours" : "Machine Days"}</Text>
            <ShowSelector view={view} setView={setView} />
        </CardHeader>
        <SimpleGrid columns={{
            base: 1,
            md: 5
        }}>
            <SpiderChart view={view} hours={hours} />
            <GridItem colSpan={{
                base: 1,
                md: 3
            }}>
                <HourlyGraph view={view} hours={hours} />
            </GridItem>
        </SimpleGrid>
    </MyCard>
};

export type { viewType };
export default MachineHours;