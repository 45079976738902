import {
    CardBody,
    CardFooter,
    CardHeader,
    Center,
    CircularProgress,
    CircularProgressLabel,
    Heading,
    HStack,
    Text,
    Tooltip,
    useColorMode,
    VStack,
    Icon,
    Box,
    Progress,
} from "@chakra-ui/react";
import dayjs from "../../../Components/Functions/dayjs";
import MyCard from "../../../Components/micro/Card";
import type { MachineType } from "../types";
import relativeTime from "dayjs/plugin/relativeTime";
import NumberFormatter from "../../../Components/micro/NumberFormatter";
import WeightFormatter from "../../../Components/micro/WeightFormatter";
import UnitFormatter from "../../../Components/micro/UnitFormatter";
import secFormatter from "../../../Components/Functions/formatters/secFormatter";
import { Link } from "react-router-dom";
import minifiedSecFormatter from "../../../Components/Functions/formatters/minifiedSecFormatter";
import { BiTimeFive, BiUser } from "react-icons/bi";
dayjs.extend(relativeTime);

const MachineCard = ({
    machine,
    minifyCharts
}: {
    machine: MachineType,
    minifyCharts: boolean
}) => {
    const { colorMode } = useColorMode();
    const { text, subText, border } = {
        text: `${colorMode}.text`,
        subText: `${colorMode}.subText`,
        border: `${colorMode}.border`
    }
    const headingText = "whiteAlpha.900";

    return <Box
        maxW={{
            base: "100%",
            sm: 350
        }}
        as={Link}
        to={`/machines/${machine.id}`}>
        <MyCard
            _hover={{
                transform: "translateY(-3px)",
                boxShadow: "xl"
            }}
            transition={"all 0.2s ease-in-out"}
            p={0}>
            <CardHeader
                h="70px"
                borderTopRadius={"md"}
                bgColor={
                    machine.status === "NA" ? "gray" :
                        machine.status === "OFF" ? "red" :
                            machine.isUpToDate !== true ? "cadetblue" :
                                machine.status === "ON" ? "green" :
                                    machine.status === "IDLE" ? "orange" :
                                        undefined
                }
                borderBottom={`1px solid`}
                borderBottomColor={border}
                display={"flex"}
                flexDir={"column"}
                justifyContent={"center"}
                py={3}>
                <Heading textAlign={"center"} color={headingText} size={"md"}>{machine.name}</Heading>
                <Text textAlign={"center"} fontSize={"xs"} color={headingText} fontWeight={600}>
                    <Tooltip
                        label={dayjs.unix(machine.statusSince).format("DD-MM-YYYY HH:mm:ss")}
                        hasArrow={true}
                        placement={"top"}
                        bg={"gray.700"}
                        color={"white"}
                        fontSize={"xs"}
                        fontWeight={400}
                        borderRadius={"md"}
                        boxShadow={"md"}
                        p={2}
                        m={0}>
                        {minifiedSecFormatter(dayjs().unix() - machine.statusSince)}
                    </Tooltip>
                    {(machine.isUpToDate === true || machine.status === "OFF") ? "" : `(${machine.isUpToDate})`}
                    {` - ${machine.status}`}
                </Text>
            </CardHeader>
            <CardBody
                p={0}
                as={VStack}>
                <Center flexDir={"column"} w="100%">
                    {minifyCharts && <VStack py={4} w="100%" gap={3} borderBottom="1px solid" borderColor={border}>
                        <HStack w="100%" justifyContent={"space-around"}>
                            <Text fontSize={"2xl"} fontWeight={700}><NumberFormatter number={machine.shots} suffix={"shot(s)"} /></Text>
                            <Text fontSize={"2xl"} fontWeight={700}><UnitFormatter number={machine.electricity} /></Text>
                        </HStack>
                    </VStack>}
                    <Tooltip
                        label={`Machine Efficiency: ${machine.efficiency}%`}
                        hasArrow={true}
                        placement={"top"}
                        bg={"gray.700"}
                        color={"white"}
                        fontSize={"xs"}
                        fontWeight={400}
                        borderRadius={"md"}
                        boxShadow={"md"}
                        p={2}
                        m={0}>
                        {minifyCharts ? <Box
                            gap={3}
                            w="100%"
                            p={2}
                            display="flex"
                            alignItems={"center"}
                            justifyContent="center"
                            textTransform={"uppercase"}
                            color={subText}
                            fontSize={"sm"}>
                            <Text fontWeight={500} opacity={0.9}>
                                OEE:
                            </Text>
                            <Box w="80%">
                                <Progress
                                    w="100%"
                                    size="lg"
                                    borderRightRadius={'md'}
                                    colorScheme={
                                        machine.efficiency >= 75 ? "green" :
                                            machine.efficiency >= 50 ? "orange" :
                                                "red"
                                    }
                                    value={
                                        (machine.efficiency) || 0
                                    }
                                />
                            </Box>
                            <Text
                                textTransform={"lowercase"}
                                as={"span"}
                                ml={2}
                                fontWeight={"bold"}
                                color={text}>
                                {machine.efficiency.toFixed(0)}%
                            </Text>
                        </Box> : <CircularProgress
                            thickness={"5px"}
                            rounded={"full"}
                            size={"250px"}
                            value={machine.efficiency}
                            color={
                                machine.efficiency >= 75 ? "green" :
                                    machine.efficiency >= 50 ? "orange" :
                                        "red"
                            }
                            trackColor={"#cccccc40"}
                            capIsRound>
                            <CircularProgressLabel>
                                <VStack>
                                    <Text fontSize={"xl"} fontWeight={600}><NumberFormatter number={machine.shots} suffix={"shot(s)"} /></Text>
                                    <Text fontSize={"xl"} fontWeight={600}><UnitFormatter number={machine.electricity} /></Text>
                                </VStack>
                            </CircularProgressLabel>
                        </CircularProgress>}
                    </Tooltip>
                    <Text fontSize={"sm"} fontWeight={600} textTransform="uppercase">
                        <Tooltip
                            label={`${dayjs.unix(machine.moldSince).fromNow()} | ${dayjs.unix(machine.moldSince).format("DD-MM-YYYY HH:mm:ss")}`}
                            hasArrow={true}
                            placement={"top"}
                            bg={"gray.700"}
                            color={"white"}
                            fontSize={"xs"}
                            fontWeight={400}
                            borderRadius={"md"}
                            boxShadow={"md"}
                            p={2}
                            m={0}>
                            {`mold: ${machine.mold}`}
                        </Tooltip>
                    </Text>
                </Center>
                <HStack
                    w="100%"
                    px={3}
                    borderTop="1px solid"
                    borderTopColor={border}>
                    <VStack alignItems={"flex-start"} py={3}>
                        <Text
                            textTransform={"uppercase"}
                            color={subText}
                            fontSize={"sm"}>
                            production: <Text
                                textTransform={"lowercase"}
                                as={"span"}
                                ml={2}
                                fontWeight={"bold"}
                                color={text}>
                                <NumberFormatter number={machine.production} suffix={"pc(s)"} />
                            </Text>
                        </Text>
                        <Text
                            textTransform={"uppercase"}
                            color={subText}
                            fontSize={"sm"}>
                            material: <Text
                                textTransform={"lowercase"}
                                as={"span"}
                                ml={2}
                                fontWeight={"bold"}
                                color={text}>
                                <WeightFormatter number={machine.material} />
                            </Text>
                        </Text>
                        <Text
                            textTransform={"uppercase"}
                            color={subText}
                            fontSize={"sm"}>
                            cycle time: <Text
                                textTransform={"lowercase"}
                                as={"span"}
                                ml={2}
                                fontWeight={"bold"}
                                color={text}>
                                {machine.cycleTime}s
                            </Text>
                        </Text>
                    </VStack>
                    <VStack
                        borderLeft={`1px solid`}
                        borderLeftColor={border}
                        pl={3}
                        alignItems={"flex-start"}
                        py={3}>
                        <Text
                            textTransform={"uppercase"}
                            color={subText}
                            fontSize={"sm"}>
                            on time: <Text
                                textTransform={"lowercase"}
                                as={"span"}
                                ml={2}
                                fontWeight={"bold"}
                                color={text}>
                                <Tooltip
                                    label={secFormatter(machine.on)}
                                    hasArrow={true}
                                    placement={"top"}
                                    bg={"gray.700"}
                                    color={"white"}
                                    fontSize={"xs"}
                                    fontWeight={400}
                                    borderRadius={"md"}
                                    boxShadow={"md"}
                                    p={2}
                                    m={0}>
                                    {minifiedSecFormatter(machine.on)}
                                </Tooltip>
                            </Text>
                        </Text>
                        <Text
                            textTransform={"uppercase"}
                            color={subText}
                            fontSize={"sm"}>
                            idle time: <Text
                                textTransform={"lowercase"}
                                as={"span"}
                                ml={2}
                                fontWeight={"bold"}
                                color={text}>
                                <Tooltip
                                    label={secFormatter(machine.idle)}
                                    hasArrow={true}
                                    placement={"top"}
                                    bg={"gray.700"}
                                    color={"white"}
                                    fontSize={"xs"}
                                    fontWeight={400}
                                    borderRadius={"md"}
                                    boxShadow={"md"}
                                    p={2}
                                    m={0}>
                                    {minifiedSecFormatter(machine.idle)}
                                </Tooltip>
                            </Text>
                        </Text>
                        <Text
                            textTransform={"uppercase"}
                            color={subText}
                            fontSize={"sm"}>
                            off time: <Text
                                textTransform={"lowercase"}
                                as={"span"}
                                ml={2}
                                fontWeight={"bold"}
                                color={text}>
                                <Tooltip
                                    label={secFormatter(machine.off)}
                                    hasArrow={true}
                                    placement={"top"}
                                    bg={"gray.700"}
                                    color={"white"}
                                    fontSize={"xs"}
                                    fontWeight={400}
                                    borderRadius={"md"}
                                    boxShadow={"md"}
                                    p={2}
                                    m={0}>
                                    {minifiedSecFormatter(machine.off)}
                                </Tooltip>
                            </Text>
                        </Text>
                    </VStack>
                </HStack>
            </CardBody>
            <CardFooter
                borderTop={`1px solid`}
                borderTopColor={border}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={2}
                px={4}>
                <Text
                    display={"flex"}
                    alignItems={"center"}
                    textAlign={"center"}
                    fontSize={"xs"}
                    color={text}
                    fontWeight={600}>
                    <Icon
                        fontSize={"md"}
                        color={text}
                        mr={1}
                        as={BiUser} />
                    <Tooltip
                        label={`Shift Timing: ${machine.shiftTiming}`}
                        hasArrow={true}
                        placement={"top"}
                        bg={"gray.700"}
                        color={"white"}
                        fontSize={"xs"}
                        fontWeight={400}
                        borderRadius={"md"}
                        boxShadow={"md"}
                        p={2}
                        m={0}>
                        {machine.operator}
                    </Tooltip>
                </Text>
                <Text
                    display={"flex"}
                    alignItems={"center"}
                    textAlign={"center"}
                    fontSize={"xs"}
                    color={text}
                    fontWeight={600}>
                    <Icon
                        fontSize={"md"}
                        color={text}
                        mr={1}
                        as={BiTimeFive} />
                    <Tooltip
                        label={dayjs.unix(machine.updated).format("DD-MM-YYYY HH:mm:ss")}
                        hasArrow={true}
                        placement={"top"}
                        bg={"gray.700"}
                        color={"white"}
                        fontSize={"xs"}
                        fontWeight={400}
                        borderRadius={"md"}
                        boxShadow={"md"}
                        p={2}
                        m={0}>
                        {dayjs.unix(machine.updated).fromNow()}
                    </Tooltip>
                </Text>
            </CardFooter>
        </MyCard>
    </Box>
}

export default MachineCard