import { SimpleGrid } from "@chakra-ui/react"
import { BsFillGearFill } from "react-icons/bs";
import { FaBoxes, FaCubes } from "react-icons/fa";
import { HiLightningBolt } from "react-icons/hi";
import Stat from "./Stat";
import { returnType } from "../types";

const StatsCard = ({
    total,
    notFound
}: {
    total: returnType["report"]["total"] | null,
    notFound: boolean
}) => {

    return <SimpleGrid w="100%" gap={5} columns={{
        base: 1,
        md: 2,
        xl: 4
    }}>
        <Stat
            title={"Shots"}
            value={total?.shots.value === undefined ? -1 : total?.shots.value}
            icon={BsFillGearFill}
            change={total?.shots.change}
            format="shots"
            notFound={notFound}
        />
        <Stat
            title={"Production"}
            value={total?.production.value === undefined ? -1 : total?.production.value}
            icon={FaBoxes}
            change={total?.production.change}
            format="pieces"
            notFound={notFound}
        />
        <Stat
            format={"units"}
            title={"Electricity"}
            value={total?.electricity.value === undefined ? -1 : total?.electricity.value}
            icon={HiLightningBolt}
            change={total?.electricity.change}
            changeInverse={true}
            notFound={notFound}
        />
        <Stat
            format={"weight"}
            title={"material"}
            value={total?.material.value === undefined ? -1 : total?.material.value}
            icon={FaCubes}
            change={total?.material.change}
            notFound={notFound}
        />
    </SimpleGrid>
}

export default StatsCard;