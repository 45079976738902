import { Box, GridItem, Text } from "@chakra-ui/react"
import { useMemo } from "react"
import { MachineStats } from "../..";
import secFormatter from "../../../../../../Components/Functions/formatters/secFormatter";
import NumberFormatter from "../../../../../../Components/micro/NumberFormatter";
import Table from "../../../../../../Components/micro/Table";
import UnitFormatter from "../../../../../../Components/micro/UnitFormatter";
import WeightFormatter from "../../../../../../Components/micro/WeightFormatter";

const headings = [
    "Machine",
    "Mold",
    "Shots",
    "Production",
    "Material",
    "Electricity",
    "Avg Cycletime",
    "On Time",
    "Idle Time",
    "Off Time",
    "Efficiency"
]

const MachineTable = ({
    machines
}: {
    machines: {
        [key: string]: MachineStats
    }
}) => {
    const data = useMemo(() => {
        return Object.values(machines).map((machine) => {
            const { name, shots, electricity, production, material, moldName, avgCycletime, efficiency, idletime, offtime, ontime } = machine;
            return [
                {
                    element: Text,
                    props: { fontWeight: 500, fontSize: "md" },
                    children: name,
                }, moldName.toUpperCase(), {
                    element: NumberFormatter,
                    props: { number: shots, suffix: "shot(s)" },
                }, {
                    element: NumberFormatter,
                    props: { number: production, suffix: "pc(s)" },
                }, {
                    element: WeightFormatter,
                    props: { number: material },
                }, {
                    element: UnitFormatter,
                    props: { number: electricity },
                },
                avgCycletime,
                secFormatter(ontime),
                secFormatter(idletime),
                secFormatter(offtime),
                efficiency + "%"
            ];
        });
    }, [machines]);

    return <>
        <GridItem colSpan={{
            base: 1,
            md: 3
        }}>
            <Box p={0} maxH="500px" overflow={"auto"}>
                <Table headings={headings} rows={data} />
            </Box>
        </GridItem>
    </>
}

export default MachineTable