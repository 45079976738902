import formatter from "./numberFormatter";

const format = (value: number, suffix?: string) => {
  if (value < 1000) {
    if (value < -1000) {
      value /= 1000;
      return formatter(+value.toFixed(2), "kg");
    }
    if (value < 0.01 && value !== 0) {
      value *= 1000;
      return formatter(+value.toFixed(2), "mg");
    }
    return formatter(+value.toFixed(2), "g");
  } else {
    value /= 1000;
    return formatter(+value.toFixed(2), "kg");
  }
};

export default format;
