import { FormControl, FormErrorMessage, FormLabel, ModalBody, ModalCloseButton, ModalHeader, useDisclosure, VStack, ModalFooter, Button, useToast, Flex, Box, Text, useColorMode } from "@chakra-ui/react";
import { Children, cloneElement, useMemo, useRef, useState } from "react";
import { BiTrashAlt } from "react-icons/bi";
import { InventoryItem } from "../..";
import Primary from "../../../../../Components/Buttons/Primary";
import PrimaryNumberInput from "../../../../../Components/Inputs/PrimaryNumberInput";
import BackdropLoader from "../../../../../Components/Loaders/BackdropLoader"
import ConfirmDialog from "../../../../../Components/micro/ConfirmDialog";
import MyModal from "../../../../../Components/micro/MyModal";

const EditMaterialModal = ({
  item,
  children,
  refetch
}: {
  item: InventoryItem,
  children: JSX.Element,
  refetch: (e?: any) => void
}) => {
  const { colorMode } = useColorMode();
  const { border } = useMemo(() => {
    return {
      border: `${colorMode}.border`
    }
  }, [colorMode]);
  const formRef = useRef<HTMLFormElement>(null);
  const toast = useToast();
  const [loading, setLoading] = useState<string | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [error, setError] = useState<null | string>(null);

  const childWithOnClick = () => {
    return Children.map(children, child => {
      return cloneElement(child, { onClick: onOpen });
    });
  };


  const update = () => {
    import("./handlers").then(async resp => {
      if (formRef.current === null) return;
      const formData = new FormData(formRef.current as HTMLFormElement);
      const quantity = parseInt(String(formData.get("quantity") || 0));
      const success = await resp.update(item.id, item.name, quantity, setError, setLoading, toast);
      if (success) {
        refetch();
        onClose();
        formRef.current.reset();
      }
    });
  }

  const deleteMaterial = () => {
    import("./handlers").then(async resp => {
      const success = await resp.deleteMaterial(item.id, item.name, setLoading, toast);
      if (success) {
        refetch();
        onClose();
      }
    });
  }

  return <>
    {loading && <BackdropLoader text={loading} />}
    {childWithOnClick()}
    <MyModal
      size={"xl"}
      isOpen={isOpen}
      onClose={onClose}>
      <form
        ref={formRef}
        onSubmit={e => {
          e.preventDefault();
        }} autoComplete="false">
        <ModalHeader borderBottom="1px solid" borderBottomColor={border}>Edit Raw-Material</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <VStack gap={4}>
            <Text fontWeight={700} fontSize="3xl" textTransform={"uppercase"} textDecor="underline" py={3}>{item.name}{item.department ? ` (${item.department})` : ""}</Text>
            <FormControl isInvalid={error !== null}>
              <FormLabel>Quantity (KGs)</FormLabel>
              <PrimaryNumberInput defaultValue={item.remaining < 0 ? 0 : (item.remaining) / 1000} name="quantity" placeholder='Quantity' />
              <FormErrorMessage>{error}</FormErrorMessage>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Flex alignItems={"center"} justifyContent="space-between" w="100%">
            <ConfirmDialog onConfirm={deleteMaterial} scope="danger">
              <Button colorScheme={"red"} size="sm" variant={"outline"} rightIcon={<BiTrashAlt />}>Remove</Button>
            </ConfirmDialog>
            <Box>
              <ConfirmDialog onConfirm={update}>
                <Primary mr={3}>Update</Primary>
              </ConfirmDialog>
              <Button onClick={onClose}>Cancel</Button>
            </Box>
          </Flex>
        </ModalFooter>
      </form>
    </MyModal>
  </>
}

export default EditMaterialModal;