import { Column } from 'primereact/column';
import { TreeTable } from 'primereact/treetable';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import { CSSProperties } from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import Table from "../Table";
import { SortedHourTree } from '../../../routes/Machines/Machine/types';
import "./stylesheet.css";

const lightHeaderStyle: CSSProperties = {
    padding: "5px 0",
    fontFamily: 'var(--chakra-fonts-heading)',
    fontWeight: 'var(--chakra-fontWeights-bold)',
    textTransform: 'uppercase',
    letterSpacing: 'var(--chakra-letterSpacings-wider)',
    textAlign: 'start',
    WebkitPaddingStart: 'var(--chakra-space-6)',
    paddingInlineStart: 'var(--chakra-space-6)',
    WebkitPaddingEnd: 'var(--chakra-space-6)',
    paddingInlineEnd: 'var(--chakra-space-6)',
    paddingTop: 'var(--chakra-space-3)',
    paddingBottom: 'var(--chakra-space-3)',
    lineHeight: 'var(--chakra-lineHeights-4)',
    fontSize: 'var(--chakra-fontSizes-xs)',
    borderBottom: 'var(--chakra-borders-1px)',
    borderColor: 'var(--chakra-colors-gray-100)',
    color: 'var(--chakra-colors-light-tableHeaderColor)',
    backgroundColor: "#f3f6f9",
    wordWrap: "normal",
};
const darkHeaderStyle: CSSProperties = {
    padding: "5px 0",
    fontFamily: 'var(--chakra-fonts-heading)',
    fontWeight: 'var(--chakra-fontWeights-bold)',
    textTransform: 'uppercase',
    letterSpacing: 'var(--chakra-letterSpacings-wider)',
    textAlign: 'start',
    WebkitPaddingStart: 'var(--chakra-space-6)',
    paddingInlineStart: 'var(--chakra-space-6)',
    WebkitPaddingEnd: 'var(--chakra-space-6)',
    paddingInlineEnd: 'var(--chakra-space-6)',
    paddingTop: 'var(--chakra-space-3)',
    paddingBottom: 'var(--chakra-space-3)',
    lineHeight: 'var(--chakra-lineHeights-4)',
    fontSize: 'var(--chakra-fontSizes-xs)',
    borderBottom: 'var(--chakra-borders-1px)',
    borderColor: 'var(--chakra-colors-gray-700)',
    color: 'var(--chakra-colors-dark-tableHeaderColor)',
    backgroundColor: "#2c3034",
    wordWrap: "normal"
};
const lightBodyStyle: CSSProperties = {
};
const darkBodyStyle: CSSProperties = {
    backgroundColor: "#212529",
    color: "RGBA(255, 255, 255, 0.80)",
    borderColor: "#2D3748"
};
const MachineHourlyTree = ({
    notFound,
    data
}: {
    notFound: boolean,
    data: SortedHourTree[]
}) => {
    const headerStyle = useColorModeValue(lightHeaderStyle, darkHeaderStyle);
    const bodyStyle = useColorModeValue(lightBodyStyle, darkBodyStyle);

    return data?.length ? <TreeTable
        // columnResizeMode="fit"
        tableStyle={{
            fontFamily: "Poppins",
            fontSize: "14px",
        }}
        value={data}>
        <Column
            bodyStyle={bodyStyle}
            headerStyle={{
                ...headerStyle,
                width: "200px"
            }} field="time" header="Time" expander></Column>
        <Column bodyStyle={bodyStyle} headerStyle={{
            width: "100px",
            ...headerStyle
        }} field="status" header="Status"></Column>
        <Column bodyStyle={bodyStyle} headerStyle={{
            width: "100px",
            ...headerStyle
        }} field="shots" header="Shot(s)"></Column>
        <Column bodyStyle={bodyStyle} headerStyle={{
            width: "100px",
            ...headerStyle
        }} field="production" header="Prod"></Column>
        <Column bodyStyle={bodyStyle} headerStyle={{
            width: "100px",
            ...headerStyle
        }} field="material" header="Mat. Consump"></Column>
        <Column bodyStyle={bodyStyle} headerStyle={{
            width: "100px",
            ...headerStyle
        }} field="electricity" header="Elec. Consump"></Column>
        <Column bodyStyle={bodyStyle} headerStyle={{
            width: "100px",
            ...headerStyle
        }} field="ontime" header="On T."></Column>
        <Column bodyStyle={bodyStyle} headerStyle={{
            width: "100px",
            ...headerStyle
        }} field="idletime" header="IDLE T."></Column>
        <Column bodyStyle={bodyStyle} headerStyle={{
            width: "100px",
            ...headerStyle
        }} field="offtime" header="Off T."></Column>
        <Column bodyStyle={bodyStyle} headerStyle={{
            width: "100px",
            ...headerStyle
        }} field="cycleTime" header="Avg. Cyc T."></Column>
        <Column bodyStyle={bodyStyle} headerStyle={{
            width: "100px",
            ...headerStyle
        }} field="operator" header="Operator"></Column>
        <Column bodyStyle={bodyStyle} headerStyle={{
            width: "100px",
            ...headerStyle
        }} field="mold" header="Mold"></Column>
        <Column bodyStyle={bodyStyle} headerStyle={{
            width: "100px",
            ...headerStyle
        }} field="product" header="Product"></Column>
    </TreeTable> : <Table
        h="100%"
        notFound={notFound}
        headings={[
            "Time",
            "Status",
            "Shot(s)",
            "Production",
            "Mat. Consumption",
            "Elec. Consumption",
            "OnTime",
            "IDLETime",
            "OffTime",
            "Avg. Cycle Time",
            "Operator",
            "Mold",
            "Product"
        ]}
        rows={[]}
    />
}

export default MachineHourlyTree