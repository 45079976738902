import { Tooltip, Tr, Td, useColorMode, CircularProgress, CircularProgressLabel, HStack, VStack, Text, Progress, Box } from '@chakra-ui/react';
import dayjs from "../../../Components/Functions/dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { MachineType } from '../types'
import NumberFormatter from '../../../Components/micro/NumberFormatter';
import WeightFormatter from '../../../Components/micro/WeightFormatter';
import UnitFormatter from '../../../Components/micro/UnitFormatter';
import secFormatter from '../../../Components/Functions/formatters/secFormatter';
import minifiedSecFormatter from '../../../Components/Functions/formatters/minifiedSecFormatter';
dayjs.extend(relativeTime);

const MachineRow = ({
    machine
}: {
    machine: MachineType
}) => {
    const { colorMode } = useColorMode();
    const { hoverBG, hoverColor, border, subText, text } = useMemo(() => ({
        hoverBG: `${colorMode}.hoverBG`,
        hoverColor: `${colorMode}.hoverColor`,
        border: `${colorMode}.border`,
        subText: `${colorMode}.subText`,
        text: `${colorMode}.text`,
    }), [colorMode]);

    return <Tr
        fontWeight={600}
        _hover={{
            bg: hoverBG,
            color: hoverColor
        }}
        transition="all 0.2s ease"
        minH="100px"
        p="0 20px">
        <Td
            borderRight={"1px solid"}
            borderColor={border}>
            <HStack gap={3}>
                <Tooltip
                    label={`Machine Efficiency: ${machine.efficiency}%`}
                    hasArrow={true}
                    placement={"top"}
                    bg={"gray.700"}
                    color={"white"}
                    fontSize={"xs"}
                    fontWeight={400}
                    borderRadius={"md"}
                    boxShadow={"md"}
                    p={2}
                    m={0}>
                    <CircularProgress
                        trackColor={"#cccccc40"}
                        capIsRound
                        value={machine.efficiency}
                        color={
                            machine.efficiency >= 75 ? "green" :
                                machine.efficiency >= 50 ? "orange" :
                                    "red"
                        }>
                        <CircularProgressLabel fontSize="xx-small">
                            {machine.efficiency}%
                        </CircularProgressLabel>
                    </CircularProgress>
                </Tooltip>
                <VStack alignItems={"flex-start"}>
                    <Text fontWeight={700} fontSize={"lg"}>
                        <Link to={`/machines/${machine.id}`}>{machine.name}</Link>
                    </Text>
                    <Text fontSize={"xs"} fontWeight={700} color={
                        machine.status === "NA" ? "gray" :
                            machine.status === "OFF" ? "red" :
                                machine.isUpToDate !== true ? "cadetblue" :
                                    machine.status === "ON" ? "green" :
                                        machine.status === "IDLE" ? "orange" :
                                            undefined
                    }>
                        <Tooltip
                            label={dayjs.unix(machine.statusSince).format("DD-MM-YYYY HH:mm:ss")}
                            hasArrow={true}
                            placement={"top"}
                            bg={"gray.700"}
                            color={"white"}
                            fontSize={"xs"}
                            fontWeight={400}
                            borderRadius={"md"}
                            boxShadow={"md"}
                            p={2}
                            m={0}>
                            {`${minifiedSecFormatter(dayjs().unix() - machine.statusSince)}${(machine.isUpToDate === true || machine.status === "OFF") ? "" : `(${machine.isUpToDate})`} • ${machine.status}`}
                        </Tooltip>
                    </Text>
                </VStack>
            </HStack>
        </Td>
        <Td>
            <VStack alignItems={"flex-start"}>
                <Text
                    textTransform={"uppercase"}
                    color={subText}
                    fontSize={"sm"}>
                    shots: <Text
                        textTransform={"lowercase"}
                        as={"span"}
                        ml={2}
                        fontWeight={"bold"}
                        color={text}>
                        <NumberFormatter number={machine.shots} suffix={"shot(s)"} />
                    </Text>
                </Text>
                <Text
                    textTransform={"uppercase"}
                    color={subText}
                    fontSize={"sm"}>
                    production: <Text
                        textTransform={"lowercase"}
                        as={"span"}
                        ml={2}
                        fontWeight={"bold"}
                        color={text}>
                        <NumberFormatter number={machine.production} suffix={"pc(s)"} />
                    </Text>
                </Text>
            </VStack>
        </Td>
        <Td>
            <VStack alignItems={"flex-start"}>
                <Text
                    textTransform={"uppercase"}
                    color={subText}
                    fontSize={"sm"}>
                    electricity: <Text
                        textTransform={"lowercase"}
                        as={"span"}
                        ml={2}
                        fontWeight={"bold"}
                        color={text}>
                        <UnitFormatter number={machine.electricity} />
                    </Text>
                </Text>
                <Text
                    textTransform={"uppercase"}
                    color={subText}
                    fontSize={"sm"}>
                    material: <Text
                        textTransform={"lowercase"}
                        as={"span"}
                        ml={2}
                        fontWeight={"bold"}
                        color={text}>
                        <WeightFormatter number={machine.material} />
                    </Text>
                </Text>
            </VStack>
        </Td>
        <Td>
            <HStack>
                <VStack
                    alignItems={"flex-start"}>
                    <Box
                        gap={3}
                        display="flex"
                        alignItems={"center"}
                        justifyContent="center"
                        textTransform={"uppercase"}
                        color={subText}
                        fontSize={"sm"}>
                        on time:
                    </Box>
                    <Box
                        gap={3}
                        display="flex"
                        alignItems={"center"}
                        justifyContent="center"
                        textTransform={"uppercase"}
                        color={subText}
                        fontSize={"sm"}>
                        idle time:
                    </Box>
                    <Box
                        gap={3}
                        display="flex"
                        alignItems={"center"}
                        justifyContent="center"
                        textTransform={"uppercase"}
                        color={subText}
                        fontSize={"sm"}>
                        off time:
                    </Box>
                </VStack>
                <VStack>
                    <Box
                        gap={3}
                        display="flex"
                        alignItems={"center"}
                        justifyContent="center"
                        textTransform={"uppercase"}
                        color={subText}
                        fontSize={"sm"}>
                        <Progress
                            w="100px"
                            size="sm"
                            borderRightRadius={'md'}
                            colorScheme="green"
                            value={
                                (machine.on / (machine.on + machine.idle + machine.off) * 100) || 0
                            }
                        />
                        <Text
                            textTransform={"lowercase"}
                            as={"span"}
                            ml={2}
                            fontWeight={"bold"}
                            color={text}>
                            <Tooltip
                                label={secFormatter(machine.on)}
                                hasArrow={true}
                                placement={"top"}
                                bg={"gray.700"}
                                color={"white"}
                                fontSize={"xs"}
                                fontWeight={400}
                                borderRadius={"md"}
                                boxShadow={"md"}
                                p={2}
                                m={0}>
                                {minifiedSecFormatter(machine.on)}
                            </Tooltip>
                        </Text>
                    </Box>
                    <Box
                        gap={3}
                        display="flex"
                        alignItems={"center"}
                        justifyContent="center"
                        textTransform={"uppercase"}
                        color={subText}
                        fontSize={"sm"}>
                        <Progress
                            w="100px"
                            size="sm"
                            borderRightRadius={'md'}
                            colorScheme="orange"
                            value={
                                (machine.idle / (machine.on + machine.idle + machine.off) * 100) || 0
                            }
                        />
                        <Text
                            textTransform={"lowercase"}
                            as={"span"}
                            ml={2}
                            fontWeight={"bold"}
                            color={text}>
                            <Tooltip
                                label={secFormatter(machine.idle)}
                                hasArrow={true}
                                placement={"top"}
                                bg={"gray.700"}
                                color={"white"}
                                fontSize={"xs"}
                                fontWeight={400}
                                borderRadius={"md"}
                                boxShadow={"md"}
                                p={2}
                                m={0}>
                                {minifiedSecFormatter(machine.idle)}
                            </Tooltip>
                        </Text>
                    </Box>
                    <Box
                        gap={3}
                        display="flex"
                        alignItems={"center"}
                        justifyContent="center"
                        textTransform={"uppercase"}
                        color={subText}
                        fontSize={"sm"}>
                        <Progress
                            w="100px"
                            size="sm"
                            borderRightRadius={'md'}
                            colorScheme="red"
                            value={
                                (machine.off / (machine.on + machine.idle + machine.off) * 100) || 0
                            }
                        />
                        <Text
                            textTransform={"lowercase"}
                            as={"span"}
                            ml={2}
                            fontWeight={"bold"}
                            color={text}>
                            <Tooltip
                                label={secFormatter(machine.off)}
                                hasArrow={true}
                                placement={"top"}
                                bg={"gray.700"}
                                color={"white"}
                                fontSize={"xs"}
                                fontWeight={400}
                                borderRadius={"md"}
                                boxShadow={"md"}
                                p={2}
                                m={0}>
                                {minifiedSecFormatter(machine.off)}
                            </Tooltip>
                        </Text>
                    </Box>
                </VStack>
            </HStack>
        </Td>
        <Td>
            <VStack
                alignItems={"flex-start"}>
                <Text
                    textTransform={"uppercase"}
                    color={subText}
                    fontSize={"sm"}>
                    mold: <Text
                        as={"span"}
                        ml={2}
                        fontWeight={"bold"}
                        color={text}>
                        <Tooltip
                            label={`${dayjs.unix(machine.moldSince).fromNow()} | ${dayjs.unix(machine.moldSince).format("DD-MM-YYYY HH:mm:ss")}`}
                            hasArrow={true}
                            placement={"top"}
                            bg={"gray.700"}
                            color={"white"}
                            fontSize={"xs"}
                            fontWeight={400}
                            borderRadius={"md"}
                            boxShadow={"md"}
                            p={2}
                            m={0}>
                            {machine.mold || "N/A"}
                        </Tooltip>
                    </Text>
                </Text>
                <Text
                    textTransform={"uppercase"}
                    color={subText}
                    fontSize={"sm"}>
                    operator: <Text
                        as={"span"}
                        ml={2}
                        fontWeight={"bold"}
                        color={text}>
                        <Tooltip
                            label={`Shift Timing: ${machine.shiftTiming}`}
                            hasArrow={true}
                            placement={"top"}
                            bg={"gray.700"}
                            color={"white"}
                            fontSize={"xs"}
                            fontWeight={400}
                            borderRadius={"md"}
                            boxShadow={"md"}
                            p={2}
                            m={0}>
                            {machine.operator}
                        </Tooltip>
                    </Text>
                </Text>
                <Text
                    textTransform={"uppercase"}
                    color={subText}
                    fontSize={"sm"}>
                    AVG. Cycle Time: <Text
                        textTransform={"lowercase"}
                        as={"span"}
                        ml={2}
                        fontWeight={"bold"}
                        color={text}>
                        {machine.cycleTime}s
                    </Text>
                </Text>
            </VStack>
        </Td>
    </Tr>
}

export default MachineRow